import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AccordingToggle from "../components/ads/accordingToggle";
import UserHeader from "../components/user-header";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { GetUserAds } from "../../api/auth";
import { RotatingLines } from "react-loader-spinner";
import { Loader } from "../components/Loader";
import { getBillboard } from "../../component/billboard/detail/billboard-detail.api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function UserDashboard() {
  const { t } = useTranslation();
  const [toogleState, setToogleState] = useState(null);
  const [toogleState1, setToogleState1] = useState(null);
  const [toogleState2, setToogleState2] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loggedIn, setloggedIn] = useState(false);

  const [pendingAds, setpendingAds] = useState([]);
  const [expiredAds, setexpiredAds] = useState([]);
  const [activeAds, setactiveAds] = useState([]);
  const [approvedAds, setapprovedAds] = useState([]);
  const [unapprovedAds, setunapprovedAds]= useState([])

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // });
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!loggedIn) {

  //   }
  // }, [loggedIn]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await GetUserAds();
        if (response.remote === "success") {
          // Process the data
          const allAds = response?.data?.data;
          // console.log("\n<UserDashboard> allAds", allAds);
          const unapprovedAds = allAds?.filter((ad) => ad.status === "unapproved");
          const pendingAds = allAds?.filter((ad) => ad.status === "pending");
          const expiredAds = allAds?.filter((ad) => ad.status === "expired");
          const approvedAds = allAds?.filter(
            (ad) =>
              ad.status === "approved" && ad.subStatus === "AWAITING_UPLOAD"
          );
          const activeAds = allAds?.filter(
            (ad) =>
              ad.status === "approved" && ad.subStatus === "VIDEO_UPLOADED"
          );
          setactiveAds(activeAds);
          setexpiredAds(expiredAds);
          setpendingAds(pendingAds);
          setapprovedAds(approvedAds);
          setunapprovedAds(unapprovedAds);
        }
        // console.log("\n\n\n<UserDashboard>response", response);
      } catch (error) {
        console.error("Error fetching user ads:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Invoke the async function
    const token = localStorage.getItem("token");
    if (!token) {
      toast.warn(t("login_warn"));
      navigate("/");
    }
  }, []);

  return (
    <Box sx={{ p: 3, mb: 10 }}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Stack
            dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
            direction={"row"}
            alignItems={"center"}
            spacing={2}
            sx={{ mb: 3 }}
          >
            {/* <IconButton
              sx={{
                background: "#fff",
                color: "#000",
                opacity: 1,
                "&:hover": { color: "#000", background: "#fff" },
              }}
              onClick={() => navigate("/user/advertising-policy")}
            >
              <KeyboardArrowLeftIcon />
            </IconButton> */}
            <Box
              sx={{ fontSize: "20px", color: "#fff", fontWeight: "600" }}
              component={"span"}
            >
              {t("dashboard")}
            </Box>
          </Stack>

          {pendingAds.length > 0 && (
            <Box
              dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
            >
              <Typography
                component={Stack}
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ color: "#FABE2C", fontSize: "16px", fontWeight: 700 }}
              >
                <span>{t("pending_ads")}</span>
                <Box
                  component={"span"}
                  sx={{ color: "#D9D9D9", fontWeight: 500 }}
                >
                  {pendingAds?.length}
                </Box>
              </Typography>
              <Box sx={{ my: 2 }}>
                <Stack direction={"column"} spacing={2}>
                  {pendingAds.map((item) => (
                    <AccordingToggle
                      key={item}
                      toogleValue={toogleState === item}
                      handleToogle={() =>
                        setToogleState(toogleState === item ? null : item)
                      }
                      value={item}
                      // adExposure={adExposure}
                      cost={item.campaign_cost}
                      billboardAd={item.billboardAddress}
                      t={t}
                    />
                  ))}
                </Stack>
              </Box>
            </Box>
          )}
          {unapprovedAds.length > 0 && (
            <Box
              dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
            >
              <Typography
                component={Stack}
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ color: "#FABE2C", fontSize: "16px", fontWeight: 700 }}
              >
                <span>{t("unapproved_ads")}</span>
                <Box
                  component={"span"}
                  sx={{ color: "#D9D9D9", fontWeight: 500 }}
                >
                  {unapprovedAds?.length}
                </Box>
              </Typography>
              <Box sx={{ my: 2 }}>
                <Stack direction={"column"} spacing={2}>
                  {unapprovedAds.map((item) => (
                    <AccordingToggle
                      key={item}
                      toogleValue={toogleState === item}
                      handleToogle={() =>
                        setToogleState(toogleState === item ? null : item)
                      }
                      value={item}
                      // adExposure={adExposure}
                      cost={item.campaign_cost}
                      billboardAd={item.billboardAddress}
                      t={t}
                    />
                  ))}
                </Stack>
              </Box>
            </Box>
          )}
          {approvedAds.length > 0 && (
            <Box>
              <Typography
                component={Stack}
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ color: "#FABE2C", fontSize: "16px", fontWeight: 700 }}
              >
                <span>{t("approved_ads")}</span>
                <Box
                  component={"span"}
                  sx={{ color: "#D9D9D9", fontWeight: 500 }}
                >
                  {approvedAds?.length}
                </Box>
              </Typography>
              <Box sx={{ my: 2 }}>
                <Stack direction={"column"} spacing={2}>
                  {approvedAds.map((item) => (
                    <AccordingToggle
                      key={item}
                      toogleValue={toogleState1 === item}
                      handleToogle={() =>
                        setToogleState1(toogleState1 === item ? null : item)
                      }
                      value={item}
                      adExposure={Math.round(item?.total_adExposure)}
                      cost={item.final_cost}
                      billboardAd={item.billboardAddress}
                      t={t}
                    />
                  ))}
                </Stack>
              </Box>
            </Box>
          )}
          {activeAds.length > 0 && (
            <Box>
              <Typography
                component={Stack}
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ color: "#FABE2C", fontSize: "16px", fontWeight: 700 }}
              >
                <span>{t("active_ads")}</span>
                <Box
                  component={"span"}
                  sx={{ color: "#D9D9D9", fontWeight: 500 }}
                >
                  {activeAds?.length}
                </Box>
              </Typography>
              <Box sx={{ my: 2 }}>
                <Stack direction={"column"} spacing={2}>
                  {activeAds.map((item) => (
                    <AccordingToggle
                      key={item}
                      toogleValue={toogleState1 === item}
                      handleToogle={() =>
                        setToogleState1(toogleState1 === item ? null : item)
                      }
                      value={item}
                      adExposure={Math.round(item?.total_adExposure)}
                      cost={item.final_cost}
                      billboardAd={item.billboardAddress}
                      t={t}
                    />
                  ))}
                </Stack>
              </Box>
            </Box>
          )}
          {expiredAds.length > 0 && (
            <Box>
              <Typography
                component={Stack}
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ color: "#FABE2C", fontSize: "16px", fontWeight: 700 }}
              >
                <span>{t("expired_ads")}</span>
                <Box
                  component={"span"}
                  sx={{ color: "#D9D9D9", fontWeight: 500 }}
                >
                  {expiredAds?.length}
                </Box>
              </Typography>
              <Box sx={{ my: 2 }}>
                <Stack direction={"column"} spacing={2}>
                  {expiredAds.map((item) => (
                    <AccordingToggle
                      key={item}
                      toogleValue={toogleState2 === item}
                      handleToogle={() =>
                        setToogleState2(toogleState2 === item ? null : item)
                      }
                      value={item}
                      adExposure={Math.round(item?.total_adExposure)}
                      billboardAd={item.billboardAddress}
                      t={t}
                    />
                  ))}
                </Stack>
              </Box>
            </Box>
          )}

          {/* Conditional rendering for no data available message */}
          {pendingAds.length === 0 &&
            activeAds.length === 0 &&
            expiredAds.length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "85vh",
                }}
              >
                <Typography
                  sx={{ color: "#fff", fontSize: "16px", fontWeight: 600 }}
                >
                  {t("no_data_available")}
                </Typography>
              </div>
            )}
          <UserHeader />
        </>
      )}
    </Box>
  );
}
