const es = {
  enter_phone: "Ingresa tu teléfono móvil",
  country_select: "Seleccionar país",
  select: "Seleccionar",
  mob_num: "Número de móvil",
  next: "Próxima",
  code_validation: "Validación de código",
  enter_otp_message:
    "Por favor ingrese el código de 4 dígitos enviado a su número de móvil",
  resend_code_message: "¿No recibió el código? Reenviar en",
  sec: "seg",
  resend: "Reenviar",
  otp_success: "Código de verificación enviado correctamente",
  otp_error: "Error al reenviar el código de verificación",
  login_warn: "¡Por favor inicia sesión!",
  first_name: "Nombre",
  last_name: "Apellido",
  date_of_birth: "Fecha de nacimiento",
  address: "Dirección",
  email: "Correo electrónico",
  phone_number: "Número de teléfono",
  payment_preference: "Preferencia de pago",
  cash: "Efectivo",
  credit_card: "Tarjeta de crédito",
  paypal: "Paypal",
  bank: "Transferencia bancaria",
  personal_info: "Información personal",
  enter_here: "Ingrese aquí",
  country: "país",
  profile: "Gestionar perfil",
  update: "Actualizar",
  billboard_location: "Ubicación del cartel publicitario",
  installation_date: "Fecha de instalación",
  running_ads: "Anuncios en ejecución",
  upload_ads: "Subir anuncios",
  no_data_available: "No hay datos disponibles",
  ad_name_title: "El nombre del anuncio vendrá aquí",
  billboard: "Cartel publicitario",
  billboard_offline:
    "Este cartel está actualmente fuera de línea, lo que impide la carga de nuevos anuncios. Nuestro equipo técnico está abordando activamente el problema",
  start_date: "Fecha de inicio",
  end_date: "Fecha de finalización",
  cost: "Costo",
  ad_exposure: "Exposición del anuncio",
  ad_performance_tracking: "Seguimiento preciso del rendimiento del anuncio",
  ad_performance_tracking_description:
    "Mida el impacto del anuncio como en Facebook. Conozca la exposición, establezca precios de campaña y pague después de la campaña",
  effective_ads_transparent_pricing:
    "Anuncios efectivos, precios transparentes",
  effective_ads_transparent_pricing_description:
    "Asegure la calidad del anuncio, establezca precios basados en la exposición. Pague una vez que finalice su campaña",
  seamless_ad_experience: "Experiencia publicitaria sin problemas",
  seamless_ad_experience_description:
    "Campañas sin esfuerzo con resultados medibles. Pague solo después de que termine su campaña",
  ad_policy: "Política publicitaria",
  ad_content_approval: "Aprobación de contenido publicitario",
  ad_content_approval_body:
    "Estamos comprometidos a mantener un entorno publicitario seguro y respetuoso. Solo se aprobará el contenido de video que cumpla con la ley. Por favor, absténgase de subir anuncios que presenten contenido prohibido como drogas, alcohol o material político.",
  ad_video_requirements: "Requisitos de video",
  ad_video_requirements_body:
    "Para garantizar la mejor calidad de visualización, solo se aprobarán archivos de video de alta calidad. Los videos deben tener una estructura de ratio 1:2 (longitud / anchura) o estar en formato de video TIKTOK.",
  ad_campaign_cost: "Costo de la campaña",
  ad_campaign_cost_body:
    "El costo de su campaña se mostrará después de cargar su archivo publicitario. Tiene la flexibilidad de ajustar la duración de la campaña o la longitud del video para modificar el costo de la campaña.",
  ad_cost_calculation: "Cálculo de costos",
  ad_cost_calculation_body:
    "Los costos de la campaña se determinan en función de varios factores, incluida la duración del video, la exposición del cartel publicitario y la duración de la campaña. Tenga en cuenta que los videos más largos en carteles publicitarios con una alta exposición resultarán en costos de campaña más altos.",
  ad_additional_note: "Nota adicional",
  ad_additional_note_body:
    "Por favor, familiarícese con nuestra política publicitaria para asegurarse de que su campaña cumpla con nuestras pautas. Estamos dedicados a ofrecer experiencias publicitarias efectivas y responsables en carteles publicitarios para todos.",
  accept_continue: "Aceptar y continuar",
  ad_name: "Nombre del Anuncio",
  campaign_start_date: "Fecha de Inicio de la Campaña",
  campaign_end_date: "Fecha de Fin de la Campaña",
  automatic_calculation: "Cálculo Automático",
  attach_video: "Adjuntar un Video",
  upload_ad: "Subir Anuncio",
  video_resolution_message:
    "La resolución del video es incorrecta, debe tener una relación de aspecto de 9:16",
  video_length_message: "La duración del video es mayor a 30 segundos.",
  video_size_message: "El tamaño del video debe ser mayor a 1MB",
  video_requirements: "Requisitos del Video",
  video_files_only: "Subir solo archivos de video (.mp4, .mov).",
  limit_30_seconds: "Limitar los videos a 30 segundos o menos.",
  video_resolution:
    "El video debe tener una resolución de 1:2 PX o ser un video de TikTok.",
  ensure_compliance:
    "Asegúrese de que los videos cumplan con la política de la empresa.",
  ad_created_success: "El nuevo anuncio se ha creado correctamente",
  ad_created_failure: "No se pudo crear el anuncio",
  thanks: "¡Gracias!",
  ad_upload_success: "Tus anuncios se han subido correctamente",
  dashboard: "Tablero",
  unapproved_ads: "Anuncios no aprobados",
  pending_ads: "Anuncios Pendientes",
  approved_ads: "Anuncios Aprobados",
  active_ads: "Anuncios Activos",
  expired_ads: "Anuncios Expirados",
  scanner: "Escáner",
  my_profile: "Mi Perfil",
  logout: "Cerrar Sesión",
  home: "Inicio",

  validation: {
    phone: "Se requiere un número de teléfono",
    firstName: "Se requiere el nombre",
    lastName: "Se requiere el apellido",
    dob: "Se requiere la fecha de nacimiento",
    city: "Se requiere la ciudad",
    email_Invalid: "Dirección de correo electrónico inválida",
    email: "Se requiere el correo electrónico",
    required: "Este campo es obligatorio",
    invalid_date_format: "Formato de fecha inválido",
    start_date_required: "Se requiere la fecha de inicio de la campaña",
    end_date_required: "Se requiere la fecha de fin de la campaña",
    end_date_after_start_date:
      "La fecha de fin de la campaña debe ser posterior a la fecha de inicio",
  },
};
module.exports = es;
