const ar = {
  enter_phone: "أدخل رقم هاتفك المحمول",
  country_select: "اختر البلد",
  select: "اختر",
  mob_num: "رقم الجوال",
  next: "التالي",

  code_validation: "تحقق من الكود",
  enter_otp_message:
    "الرجاء إدخال الرمز المكون من 4 أرقام المرسل إلى رقم هاتفك المحمول",
  resend_code_message: "لم تستلم الرمز؟ أعد إرساله خلال ",
  sec: "ثانية",
  resend: "إعادة إرسال",
  otp_success: "تم إرسال رمز التحقق بنجاح",
  otp_error: "خطأ في إعادة إرسال رمز التحقق",
  login_warn: "من فضلك قم بتسجيل الدخول!",

  first_name: "الاسم الأول",
  last_name: "الاسم الأخير",
  date_of_birth: "تاريخ الميلاد",
  address: "العنوان",
  email: "البريد الإلكتروني",
  phone_number: "رقم الهاتف",
  payment_preference: "تفضيلات الدفع",
  cash: "نقداً",
  credit_card: "بطاقة الائتمان",
  paypal: "بايبال",
  bank: "تحويل بنكي",
  personal_info: "المعلومات الشخصية",
  enter_here: "أدخل هنا",
  country: "البلد",
  profile: "إدارة الملف الشخصي",
  update: "تحديث",

  billboard_location: "موقع اللوحة الإعلانية",
  installation_date: "تاريخ التثبيت",
  running_ads: "الإعلانات الجارية",
  upload_ads: "رفع الإعلانات",
  no_data_available: "لا تتوفر بيانات",
  ad_name_title: "سيظهر اسم الإعلان هنا",
  billboard: "اللوحة الإعلانية",
  start_date: "تاريخ البدء",
  end_date: "تاريخ الانتهاء",
  cost: "التكلفة",
  ad_exposure: "عدد المشاهدات الإعلانية",
  billboard_offline:
    "هذا اللوح إعلاني غير متصل بالإنترنت حاليًا، مما يمنع تحميل إعلانات جديدة. يعمل فريقنا التقني على حل المشكلة بنشاط",

  ad_performance_tracking: "تتبع أداء الإعلان بدقة",
  ad_performance_tracking_description:
    "قم بقياس تأثير الإعلان تمامًا مثل فيسبوك. اعرف عدد المشاهدات الإعلانية، وقم بتعيين أسعار الحملات، وادفع بعد انتهاء الحملة",
  effective_ads_transparent_pricing: "إعلانات فعّالة، تسعير شفاف",
  effective_ads_transparent_pricing_description:
    "تأكد من جودة الإعلان، وقم بتعيين الأسعار استنادًا إلى عدد المشاهدات الإعلانية. ادفع بمجرد انتهاء حملتك",
  seamless_ad_experience: "تجربة إعلانية سلسة",
  seamless_ad_experience_description:
    "حملات بدون جهد مع نتائج قابلة للقياس. ادفع فقط بعد انتهاء حملتك",

  ad_policy: "سياسة الإعلان",
  ad_content_approval: "موافقة المحتوى",
  ad_content_approval_body:
    "نحن ملتزمون بالحفاظ على بيئة إعلانية آمنة ومحترمة. سيتم الموافقة فقط على محتوى الفيديو الذي يتوافق مع القانون. يرجى الامتناع عن تحميل الإعلانات التي تحتوي على محتوى محظور مثل المخدرات أو الكحول أو المواد السياسية.",
  ad_video_requirements: "متطلبات الفيديو",
  ad_video_requirements_body:
    "لضمان أفضل جودة عرض، سيتم الموافقة فقط على ملفات الفيديو عالية الجودة. يجب أن يكون للفيديوهات هيكل بنسبة 1:2 (الطول / العرض) أو أن تكون في تنسيق ملف الفيديو TIKTOK.",
  ad_campaign_cost: "تكلفة الحملة الإعلانية",
  ad_campaign_cost_body:
    "سيتم عرض تكلفة حملتك بعد رفع ملف الإعلان الخاص بك. لديك المرونة لتعديل مدة الحملة أو طول الفيديو لتعديل تكلفة الحملة.",
  ad_cost_calculation: "حساب التكلفة",
  ad_cost_calculation_body:
    " تتم تكلفة الحملات استنادًا إلى عوامل مختلفة، بما في ذلك طول الفيديو، وعدد مشاهدات اللوحة الإعلانية، ومدة الحملة. يرجى ملاحظة أن الفيديوهات الطويلة على اللوحات الإعلانية ذات المشاهدات العالية ستؤدي إلى زيادة تكلفة الحملة.",
  ad_additional_note: "ملاحظة إضافية",
  ad_additional_note_body:
    " يرجى التعرف على سياسة الإعلان لدينا لضمان أن حملتك تتوافق مع إرشاداتنا. نحن ملتزمون بتقديم تجارب إعلانية فعّالة ومسؤولة لجميع.",
  accept_continue: "قبول والمتابعة",

  ad_name: "اسم الإعلان",
  campaign_start_date: "تاريخ بدء الحملة",
  campaign_end_date: "تاريخ انتهاء الحملة",
  automatic_calculation: "حساب تلقائي",
  attach_video: "إرفاق فيديو",
  upload_ad: "رفع الإعلان",
  video_resolution_message: "دقة الفيديو غير صحيحة يجب أن تكون بنسبة 9:16",
  video_length_message: "طول الفيديو أكبر من 30 ثانية.",
  video_size_message: "حجم الفيديو يجب أن يكون أكبر من 1 ميغابايت",
  video_requirements: "متطلبات الفيديو",
  video_files_only: "قم بتحميل ملفات الفيديو فقط (.mp4، .mov).",
  limit_30_seconds: "حدد مدة الفيديو إلى 30 ثانية أو أقل.",
  video_resolution: "يجب أن يكون للفيديو دقة بنسبة 1:2 PX أو فيديو TikTok.",
  ensure_compliance: "تأكد من أن الفيديوهات تتوافق مع سياسة الشركة.",
  ad_created_success: "تم إنشاء الإعلان بنجاح",
  ad_created_failure: "غير قادر على إنشاء الإعلان",
  thanks: "شكرًا لكم!!",
  ad_upload_success: "تم رفع الإعلانات بنجاح",

  dashboard: "لوحة القيادة",
  pending_ads: "الإعلانات المعلقة",
  approved_ads: "الإعلانات الموافق عليها",
  active_ads: "الإعلانات النشطة",
  expired_ads: "الإعلانات المنتهية",
  unapproved_ads: "الإعلانات غير المعتمدة",

  scanner: "ماسح ضوئي",

  my_profile: "ملفي",
  logout: "تسجيل الخروج",

  home: "الصفحة الرئيسية",

  validation: {
    phone: "رقم الهاتف مطلوب",
    firstName: "الاسم الأول مطلوب",
    lastName: "الاسم الأخير مطلوب",
    dob: "تاريخ الميلاد مطلوب",
    city: "المدينة مطلوبة",
    email_Invalid: "عنوان البريد الإلكتروني غير صالح",
    email: "البريد الإلكتروني مطلوب",
    required: "هذا الحقل مطلوب",
    invalid_date_format: "تنسيق التاريخ غير صالح",
    start_date_required: "تاريخ بدء الحملة مطلوب",
    end_date_required: "تاريخ انتهاء الحملة مطلوب",
    end_date_after_start_date:
      "يجب أن يكون تاريخ انتهاء الحملة بعد تاريخ بدء الحملة",
  },
};

module.exports = ar;
