const ru = {
  enter_phone: "Введите ваш мобильный телефон",
  country_select: "Выберите страну",
  select: "Выбрать",
  mob_num: "Мобильный номер",
  next: "Далее",

  code_validation: "Проверка кода",
  enter_otp_message:
    "Пожалуйста, введите 4-значный код, отправленный на ваш мобильный номер",
  resend_code_message: "Не получили код? Повторно отправить через ",
  sec: "сек",
  resend: "Отправить повторно",
  otp_success: "Код подтверждения успешно отправлен",
  otp_error: "Ошибка при повторной отправке кода подтверждения",
  login_warn: "Пожалуйста, войдите!",

  first_name: "Имя",
  last_name: "Фамилия",
  date_of_birth: "Дата рождения",
  address: "Адрес",
  email: "Электронная почта",
  phone_number: "Номер телефона",
  payment_preference: "Предпочтение оплаты",
  cash: "Наличные",
  credit_card: "Кредитная карта",
  paypal: "PayPal",
  bank: "Банковский перевод",
  personal_info: "Личная информация",
  enter_here: "Введите здесь",
  country: "страна",
  profile: "Управление профилем",
  update: "Обновить",

  billboard_location: "Местоположение рекламы",
  installation_date: "Дата установки",
  running_ads: "Запущенные рекламы",
  upload_ads: "Загрузить рекламу",
  no_data_available: "Данные недоступны",
  ad_name_title: "Название рекламы здесь",
  billboard: "Билборд",
  start_date: "Дата начала",
  end_date: "Дата окончания",
  cost: "Стоимость",
  ad_exposure: "Показы рекламы",
  billboard_offline:
    "Этот билборд в настоящее время находится в автономном режиме, что предотвращает загрузку новых рекламных объявлений. Наша техническая команда активно работает над решением проблемы",

  ad_performance_tracking: "Точный отслеживание эффективности рекламы",
  ad_performance_tracking_description:
    "Измеряйте воздействие рекламы, как на Facebook. Знаете охват, устанавливайте цены на кампанию и платите после завершения",
  effective_ads_transparent_pricing:
    "Эффективные объявления, прозрачная ценообразование",
  effective_ads_transparent_pricing_description:
    "Обеспечивайте качественное объявление, устанавливайте цены на основе охвата. Оплачивайте после завершения кампании",
  seamless_ad_experience: "Безупречный опыт рекламы",
  seamless_ad_experience_description:
    "Легкие кампании с измеримыми результатами. Оплата только после завершения вашей кампании",

  ad_policy: "Политика рекламы",
  ad_content_approval: "Подтверждение контента",
  ad_content_approval_body:
    "Мы стремимся поддерживать безопасную и уважительную рекламную среду. Будут одобрены только видеоролики, соответствующие закону. Пожалуйста, не загружайте рекламные объявления с запрещенным контентом, таким как наркотики, алкоголь или политический материал.",
  ad_video_requirements: "Требования к видео",
  ad_video_requirements_body:
    "Для обеспечения наилучшего качества изображения будут одобрены только видеоролики высокого качества. Видео должны иметь структуру соотношения 1:2 (длина/ширина) или быть в формате видеороликов TIKTOK.",
  ad_campaign_cost: "Стоимость кампании",
  ad_campaign_cost_body:
    "Стоимость вашей кампании будет отображаться после загрузки файла с рекламой. У вас есть возможность изменить длительность кампании или длину видеоролика, чтобы изменить стоимость кампании.",
  ad_cost_calculation: "Расчет стоимости",
  ad_cost_calculation_body:
    " Стоимость кампании определяется на основе различных факторов, включая длину видеоролика, экспозицию на билборде и продолжительность кампании. Обратите внимание, что более длинные видеоролики на билбордах с высокой экспозицией приведут к более высоким затратам на кампанию.",
  ad_additional_note: "Дополнительное примечание",
  ad_additional_note_body:
    " Пожалуйста, ознакомьтесь с нашей рекламной политикой, чтобы ваша кампания соответствовала нашим рекомендациям. Мы посвящены достижению эффективного и ответственного опыта рекламы на билбордах для всех.",
  accept_continue: "Принять и продолжить",

  ad_name: "Название рекламы",
  campaign_start_date: "Дата начала кампании",
  campaign_end_date: "Дата окончания кампании",
  automatic_calculation: "Автоматический расчет",
  attach_video: "Прикрепить видео",
  upload_ad: "Загрузить рекламу",
  video_resolution_message:
    "Разрешение видео неверное, должно быть в соотношении 9:16",
  video_length_message: "Продолжительность видео превышает 30 секунд.",
  video_size_message: "Размер видео должен быть больше 1 МБ",
  video_requirements: "Требования к видео",
  video_files_only: "Загружать только видеофайлы (.mp4, .mov).",
  limit_30_seconds: "Ограничьте видеоролики до 30 секунд или меньше.",
  video_resolution:
    "Видео должно иметь разрешение 1:2 PX или быть видеороликом TikTok.",
  ensure_compliance:
    "Убедитесь, что видеоролики соответствуют корпоративной политике.",
  ad_created_success: "Новая реклама успешно создана",
  ad_created_failure: "Не удалось создать рекламу",
  thanks: "Спасибо!!",
  ad_upload_success: "Ваши рекламные объявления успешно загружены",

  dashboard: "Панель управления",
  pending_ads: "Ожидающие объявления",
  approved_ads: "Утвержденные объявления",
  active_ads: "Активные объявления",
  expired_ads: "Истекшие объявления",
  unapproved_ads: "Неодобренные объявления",

  scanner: "Сканер",

  my_profile: "Мой профиль",
  logout: "Выйти",

  home: "Домой",

  validation: {
    phone: "Требуется номер телефона",
    firstName: "Требуется имя",
    lastName: "Требуется фамилия",
    dob: "Требуется дата рождения",
    city: "Требуется город",
    email_Invalid: "Недопустимый адрес электронной почты",
    email: "Требуется адрес электронной почты",
    required: "Это поле обязательно для заполнения",
    invalid_date_format: "Неверный формат даты",
    start_date_required: "Требуется дата начала кампании",
    end_date_required: "Требуется дата окончания кампании",
    end_date_after_start_date:
      "Дата окончания кампании должна быть после даты начала кампании",
  },
};

module.exports = ru;
