const fr = {
  enter_phone: "Entrez votre téléphone portable",
  country_select: "Sélectionnez le pays",
  select: "Sélectionner",
  mob_num: "Numéro de portable",
  next: "Suivant",

  code_validation: "Validation du code",
  enter_otp_message:
    "Veuillez entrer le code à 4 chiffres envoyé à votre numéro de portable",
  resend_code_message: "Vous n'avez pas reçu le code? Renvoyer dans ",
  sec: "sec",
  resend: "Renvoyer",
  otp_success: "Code de vérification envoyé avec succès",
  otp_error: "Erreur lors de la réémission du code de vérification",
  login_warn: "Veuillez vous connecter !",

  first_name: "Prénom",
  last_name: "Nom de famille",
  date_of_birth: "Date de naissance",
  address: "Adresse",
  email: "Email",
  phone_number: "Numéro de téléphone",
  payment_preference: "Préférence de paiement",
  cash: "Espèces",
  credit_card: "Carte de crédit",
  paypal: "Paypal",
  bank: "Virement bancaire",
  personal_info: "Informations personnelles",
  enter_here: "Entrer ici",
  country: "pays",
  profile: "Gérer le profil",
  update: "Mettre à jour",

  billboard_location: "Emplacement de la publicité",
  installation_date: "Date d'installation",
  running_ads: "Publicités en cours",
  upload_ads: "Télécharger des publicités",
  no_data_available: "Aucune donnée disponible",
  ad_name_title: "Le nom de la publicité apparaîtra ici",
  billboard: "Panneau d'affichage",
  start_date: "Date de début",
  end_date: "Date de fin",
  cost: "Coût",
  ad_exposure: "Expositions publicitaires",
  billboard_offline:
    "Ce panneau d'affichage est actuellement hors ligne, ce qui empêche le téléchargement de nouvelles publicités. Notre équipe technique s'occupe activement du problème",

  ad_performance_tracking: "Suivi précis de la performance publicitaire",
  ad_performance_tracking_description:
    "Mesurez l'impact publicitaire comme sur Facebook. Connaître l'exposition, définir les prix de la campagne et payer après la campagne",
  effective_ads_transparent_pricing:
    "Publicités efficaces, tarification transparente",
  effective_ads_transparent_pricing_description:
    "Assurez la qualité de la publicité, fixez les prix en fonction de l'exposition. Payer une fois votre campagne terminée",
  seamless_ad_experience: "Expérience publicitaire sans faille",
  seamless_ad_experience_description:
    "Campagnes sans effort avec des résultats mesurables. Payer uniquement après la conclusion de votre campagne",

  ad_policy: "Politique publicitaire",
  ad_content_approval: "Approbation du contenu",
  ad_content_approval_body:
    "Nous nous engageons à maintenir un environnement publicitaire sûr et respectueux. Seul le contenu vidéo conforme à la loi sera approuvé. Veuillez vous abstenir de télécharger des publicités présentant un contenu interdit tel que les drogues, l'alcool ou le matériel politique.",
  ad_video_requirements: "Exigences vidéo",
  ad_video_requirements_body:
    "Pour garantir la meilleure qualité d'affichage, seuls les fichiers vidéo de haute qualité seront approuvés. Les vidéos doivent avoir une structure en ratio 1:2 (longueur/largeur) ou être au format de fichier vidéo TIKTOK.",
  ad_campaign_cost: "Coût de la campagne",
  ad_campaign_cost_body:
    "Le coût de votre campagne sera affiché après le téléchargement de votre fichier publicitaire. Vous avez la flexibilité d'ajuster la durée de la campagne ou la durée de la vidéo pour modifier le coût de la campagne.",
  ad_cost_calculation: "Calcul des coûts",
  ad_cost_calculation_body:
    "Les coûts de la campagne sont déterminés en fonction de divers facteurs, notamment la durée de la vidéo, l'exposition du panneau d'affichage et la durée de la campagne. Veuillez noter que des vidéos plus longues sur des panneaux d'affichage avec une exposition élevée entraîneront des coûts de campagne plus élevés.",
  ad_additional_note: "Note supplémentaire",
  ad_additional_note_body:
    " Veuillez vous familiariser avec notre politique publicitaire pour garantir que votre campagne respecte nos directives. Nous nous engageons à fournir des expériences publicitaires sur panneau d'affichage efficaces et responsables pour tous.",
  accept_continue: "Accepter et continuer",

  ad_name: "Nom de la publicité",
  campaign_start_date: "Date de début de la campagne",
  campaign_end_date: "Date de fin de la campagne",
  automatic_calculation: "Calcul automatique",
  attach_video: "Joindre une vidéo",
  upload_ad: "Télécharger la publicité",
  video_resolution_message:
    "La résolution de la vidéo est incorrecte, elle doit être en ratio 9:16",
  video_length_message: "La durée de la vidéo est supérieure à 30 secondes.",
  video_size_message: "La taille de la vidéo doit être supérieure à 1 Mo",
  video_requirements: "Exigences de la vidéo",
  video_files_only: "Téléchargez uniquement des fichiers vidéo (.mp4, .mov).",
  limit_30_seconds: "Limitez les vidéos à 30 secondes ou moins.",
  video_resolution:
    "La vidéo doit avoir une résolution de 1:2 PX ou être une vidéo TikTok.",
  ensure_compliance:
    "Assurez-vous que les vidéos sont conformes à la politique de l'entreprise.",
  ad_created_success: "Nouvelle publicité créée avec succès",
  ad_created_failure: "Impossible de créer la publicité",
  thanks: "Merci!!",
  ad_upload_success: "Vos publicités ont été téléchargées avec succès",

  dashboard: "Tableau de bord",
  pending_ads: "Annonces en attente",
  approved_ads: "Annonces approuvées",
  active_ads: "Annonces actives",
  expired_ads: "Annonces expirées",
  unapproved_ads: "Annonces non approuvées",

  scanner: "Scanner",

  my_profile: "Mon profil",
  logout: "Déconnexion",

  home: "Accueil",

  validation: {
    phone: "Le numéro de téléphone est requis",
    firstName: "Le prénom est requis",
    lastName: "Le nom de famille est requis",
    dob: "La date de naissance est requise",
    city: "La ville est requise",
    email_Invalid: "Adresse e-mail invalide",
    email: "L'email est requis",
    required: "Ce champ est requis",
    invalid_date_format: "Format de date invalide",
    start_date_required: "La date de début de la campagne est requise",
    end_date_required: "La date de fin de la campagne est requise",
    end_date_after_start_date:
      "La date de fin de la campagne doit être après la date de début",
  },
};
module.exports = fr;
